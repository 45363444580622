import React, {Component} from "react";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TranslateIcon from '@mui/icons-material/Translate';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import Avatar from "@mui/material/Avatar";
import CloseIcon from '@mui/icons-material/Close';
import {AvatarGroup, IconButton, Tooltip} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';
import eventBus from "../../helpers/eventBus";
import "./chatHeader.css";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import {req_get_session} from "../../helpers/requests";

export default class ChatHeader extends Component {
    state = {
        rows: [],
        sessionObject: {},
        currentSessionId: "",
        editNameOpen: false,
        spur: false,
        isOwner: false
    }


    componentDidMount()
    {
        this.loadSession()
        this.setState({remount: true})
        this.setState({isOwner: false})
        eventBus.on("updateHeader", (uObj) => {
            this.setState({isOwner: false})
            this.loadSession()
        });
    }


    loadSession = () => {
        let self = this;
        req_get_session(localStorage.getItem("cSessionId"))
            .then(function (result) {
                if (result.status === 200) {
                    localStorage.setItem("cSession", JSON.stringify(result.data.session));



                        //console.log("session loaded", result)

                    //console.log(this.props.detailObj)

                    if (result.data.session.hasOwnProperty("sessionMembers")) {
                        self.setState({isOwner: false})
                        result.data.session.sessionMembers.forEach(
                            (member) => {
                                if (member.userId === localStorage.getItem("uid")) {
                                    if (member.role === "owner") {
                                        self.setState({isOwner: true})
                                    }
                                }
                            }
                        )
                    }
                    eventBus.dispatch("updateSubmissionContainer", {})
                    eventBus.dispatch("updateLanguages", {})
                    if (!self.state.currentSessionId) {
                        self.setState({currentSessionId: localStorage.getItem("cSessionId")})
                    }
                    self.setState({sessionObject: result.data.session, currentSessionId: localStorage.getItem("cSessionId")})

                }
            })

    }

    getSessionTypeImage = (sessionType) => {
        if (this.state.sessionObject) {
            if (this.state.sessionObject.sessionType === "conversation") {
                return (<QuestionAnswerIcon />)
            } else if (this.state.sessionObject.sessionType === "translate") {
                return (<TranslateIcon />)
            } else if (this.state.sessionObject.sessionType === "shared") {
                return (<InterpreterModeIcon />)
            }
        }
    }


    render() {

        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                { this.state.sessionObject.id ?
                    <div style={{display: "flex", justifyContent: "left", minHeight: "49px"}}>
                        <div style={{paddingRight: "10px", paddingTop: "10px"}}><this.getSessionTypeImage /></div>

                        {this.state.sessionObject.sessionType === "quickConnect" &&
                        !this.state.isOwner ?
                            <div style={{paddingRight: "10px", paddingTop: "10px"}}>
                                <IconButton aria-describedby={"autoplay_switch"} variant="contained"
                                            onClick={this.props.toggleAutoplay}>
                                    {this.props.autoplay === "true" || this.props.autoplay === true ?
                                        <VolumeUpIcon style={{color: "#758a00", fontSize: "22px"}}/> :
                                        <VolumeOffIcon style={{color: "#8a0000", fontSize: "22px"}}/>
                                    }
                                </IconButton>
                            </div> : null
                        }

                        <div style={{fontSize: ".9rem"}}>{this.state.sessionObject.name}
                            {this.state.isOwner ?
                                <IconButton onClick={() => {
                                    this.props.openChatRename(this.state.sessionObject)
                                }}>
                                    <EditIcon style={{fontSize: "16px"}} />
                                </IconButton>
                                    : null
                            }
                            <br />
                            <div style={{fontSize: ".7rem"}}>
                                [{ this.state.sessionObject && this.state.sessionObject.hasOwnProperty("languages") ? this.state.sessionObject.languages.join(', ') : "" }]
                             <span style={{display: "none"}}>Created: {this.state.sessionObject ? new Date(this.state.sessionObject.startTime).toLocaleString('en-US') : ""}</span>
                            </div>

                        </div>
                        {(this.state.sessionObject && this.state.sessionObject.hasOwnProperty("sessionType") &&
                            (this.state.sessionObject.sessionType === "conversation" || this.state.sessionObject.sessionType === "quickConnect")) ?
                            <div style={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                paddingTop: "7px",
                                borderLeft: "1px solid #ddd"
                            }}>
                                <AvatarGroup sx={{
                                    '& .MuiAvatar-root': {
                                        boxSizing: "border-box",
                                        width: 24,
                                        height: 24,
                                        fontSize: ".7rem",
                                    },
                                }}
                                             max={3}>
                                {this.state.sessionObject.sessionMembers.map((member) => {
                                return (<Tooltip key={member.userId} title={member.displayName}>
                                    <Avatar
                                    key={member.userId}
                                    sx={{

                                        bgcolor: this.props.detailObj.hasOwnProperty("userProps")
                                            && this.props.detailObj.userProps.hasOwnProperty(member.userId)
                                            && this.props.detailObj.userProps[member.userId].hasOwnProperty("avatarColor")
                                            ? this.props.detailObj.userProps[member.userId].avatarColor : "#ddd",
                                        width: 24,
                                        height: 24,
                                        fontSize: ".7rem",
                                        color: "#fff",
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex"
                                    }}

                                    alt={member.displayName}
                                    src={this.props.detailObj.hasOwnProperty("userProps")
                                    && this.props.detailObj.userProps.hasOwnProperty(member.userId)
                                    && this.props.detailObj.userProps[member.userId].hasOwnProperty("avatarUrl")
                                    ?
                                        "https://firebasestorage.googleapis.com/v0/b/simpledataset-prod.appspot.com/o/" + this.props.detailObj.userProps[member.userId].avatarUrl.replace("/", "%2F") + "?alt=media"
                                         : null}
                                >{member.displayName[0]}</Avatar></Tooltip>)
                            })
                                }</AvatarGroup></div> : null
                        }

                        {(this.state.sessionObject && this.state.sessionObject.sessionType === "conversation") ?
                        <div style={{marginLeft: "5px"}}>
                            <IconButton
                                    onClick={() => {this.props.openChatInvite(this.state.sessionObject)}}
                                    ><GroupAddIcon /></IconButton>
                        </div> : null
                        }

                        {this.state.isOwner ?
                            <div style={{marginLeft: "5px", borderLeft: "1px solid #ddd"}}>
                                <IconButton
                                    onClick={() => {
                                        this.props.openChatDelete(this.state.sessionObject)
                                    }}
                                ><DeleteForeverIcon/></IconButton>
                            </div>
                            : null
                        }

                    </div>
                    : null
                }

                {!this.props.isAnonymous ?
                    <div><IconButton onClick={this.props.clearSession}><CloseIcon/></IconButton></div>
                    : null
                }

            </div>
        )
    }
}

import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormControlLabel,
    FormLabel, Radio, RadioGroup,
    TextField
} from "@mui/material";

import Link from "@mui/material/Link";
import {req_delete_session} from "../../helpers/requests";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";

export default class ListeningOptions extends Component {

    state = {
        open: true,
        micContinuous: false,
    };

    componentDidMount() {
        console.log("listeningOptions mounted")
        if (localStorage.getItem("micContinuous")){
            this.setState({micContinuous: localStorage.getItem("micContinuous") === "true"})
        }
    }

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    updateListening = () => {
        localStorage.setItem("micContinuous", this.state.micContinuous);
        this.setState({open: false});
        this.props.close()
    }


    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
            >
                <DialogTitle>{content.micListeningOptionsPanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>

                        <FormControl style={{margin: "0 auto", width: 300}}>
                            <FormLabel id="demo-row-radio-buttons-group-label">{content.micListeningOptionsPanel[localStorage.getItem("siteLanguage") || "en-US"].subtitle}</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                onChange={(e) => {
                                    this.setState({micContinuous: e.target.value})
                                }}
                                value={this.state.micContinuous}

                            >
                                <FormControlLabel value={false} style={{margin: 10}} control={<Radio />} label={content.micListeningOptionsPanel[localStorage.getItem("siteLanguage") || "en-US"].micTriggered} />
                                <FormControlLabel value={true}

                                                  disabled={
                                                      (JSON.parse(localStorage.getItem("cSession"))).sessionType === "shared"
                                                  }
                                                  style={{margin: 10}} control={<Radio />} label={content.micListeningOptionsPanel[localStorage.getItem("siteLanguage") || "en-US"].micContinuous} />

                            </RadioGroup>
                        </FormControl>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={this.updateListening}>{content.micListeningOptionsPanel[localStorage.getItem("siteLanguage") || "en-US"].saveButton}</Button>
                    <Button variant={"outlined"} onClick={this.handleClose}>{content.micListeningOptionsPanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>

                </DialogActions>
            </Dialog>
        );
    }
}

import {req_get_all_sessions, req_get_session} from "./requests";
import eventBus from "./eventBus";

export function setLocalStorageVals(userObj) {}

export function createChatSession(sessionId) {
    localStorage.setItem("cSessionId", sessionId);
}

export function createChatSessionObject(sessionId) {
    req_get_session(sessionId)
        .then(function (result) {
            if (result.status === 200) {
                console.log("session loaded", result)
                localStorage.setItem("cSession", JSON.stringify(result.data.session));
                eventBus.dispatch("updateSubmissionContainer", {})
            }
        })
}

export function loadSessions() {
    req_get_all_sessions()
        .then(function (result) {
            if (result.status === 200) {
                localStorage.setItem("chatSessions", JSON.stringify(result.data.sessions));
                eventBus.dispatch("updateDashboard", {})
            }
        })
}


export function createChatSessionUser(userId) {
    localStorage.setItem("cSessionUserId", userId);
}

export function createSelectedAccount(selectedAccountId) {
    localStorage.setItem("selectedAccount", selectedAccountId);
}

export function clearChatSession() {
    localStorage.setItem("cSessionId", "");
}

export function clearChatSessionObject() {
    localStorage.setItem("cSession", "");
}

export function clearChatSessionUser() {
    localStorage.setItem("cSessionUserId", "");
}

export function clearSelectedAccount() {
    localStorage.setItem("selectedAccount", "");
}

export function setSuppressRestartState(val) {
    localStorage.setItem("suppressRestart", val.toString());
}

export function setListeningState(val) {
    localStorage.setItem("listeningState", val.toString());
}

export function setOtherInvokerState(val) {
    localStorage.setItem("otherInvoker", val.toString());
}

export function getSuppressRestartState() {
    return localStorage.getItem("suppressRestart") === "true";
}

export function getListeningState() {
    return localStorage.getItem("listeningState") === "true";
}

export function getMicContious() {
    return localStorage.getItem("micContinuous") === "true";
}


export function getOtherInvokerState() {
    return localStorage.getItem("otherInvoker") === "true";
}




export function languageEval(lang) {
    if (lang === "en") {
        return "en-US"
    } else if (lang === "es") {
        return "es-ES"
    }  else if (lang === "fr") {
        return "fr-FR"
    }
}


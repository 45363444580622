import React, {Component} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField
} from "@mui/material";

import LanguageSelector from "../embeddedSelectors/languageSelector";
import {req_join_session, req_update_language} from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";
import eventBus from "../../helpers/eventBus";
import {content} from "../../globals/content";
import EditIcon from "@mui/icons-material/Edit";
import Popover from "@mui/material/Popover";


export default class ChangeChatLanguage extends Component {

    state = {
        open: true,
        conversationType: "join",
        sessionCode: "",
        language: "",
        changeLanguage: "",
        changeSecondaryLanguage: "",
        secondaryLanguage: "",
        chatSessionId: "",
        anchorEl: null,
        changeLanguageOpen: false
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({language: this.props.myLanguage || "en-US"});


        eventBus.on("updateLanguages", (uObj) => {
            console.log("bus update sessions")
            this.getLanguageFromSession();
        });

        this.getLanguageFromSession();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sessionCode !== this.props.sessionCode) {
            this.getLanguageFromSession();
        }
    }


    getLanguageFromSession = () => {
        let self = this;
        console.log("getLanguageFromSession")
        this.setState({language: "", secondaryLanguage: ""});
        //let sessions = JSON.parse(localStorage.getItem("chatSessions")) || [];

        let session = JSON.parse(localStorage.getItem("cSession")) || {};
        console.log("SESSION:", session)

        //sessions.forEach((session) => {
        if (session.id === localStorage.getItem("cSessionId")) {
            session.sessionMembers.forEach((member) => {

                if (member.userId === localStorage.getItem("uid")){
                    self.setState({language: member.language, changeLanguage: member.language});

                    if (session.sessionType === "shared")
                    {
                        session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                self.setState({secondaryLanguage: lang, changeSecondaryLanguage: lang});
                            }
                        })
                    }
                }
            })
        }
        //})


    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({changeLanguage: lang});
    }


    setSecondaryLanguage = (lang) => {
        this.setState({changeSecondaryLanguage: lang});
    }




    updateSession = () => {

        console.log(this.state.language, localStorage.getItem("cSessionId"))
        let self = this;


        eventBus.dispatch("showLoader", {showLoader: true})

        if (this.props.sessionType !== "shared") {

            req_join_session(this.props.sessionCode, this.state.changeLanguage).then((response) => {
                console.log("req_join_session response", response);
                if (response.status === 200) {
                    self.setState({language: this.state.changeLanguage, changeLanguageOpen: false})
                    self.handleClose();
                    eventBus.dispatch("updateHeader", {rerun: false});
                    eventBus.dispatch("showLoader", {showLoader: false})

                } else {
                    alert("Invalid session code", localStorage.getItem("cSessionId"));
                    eventBus.dispatch("showLoader", {showLoader: false})
                }
            })
        } else {
            console.log("shared update")
            eventBus.dispatch("showLoader", {showLoader: false})
            req_update_language(this.props.sessionCode, this.state.changeLanguage, this.state.changeSecondaryLanguage).then((response) => {
                console.log("req_update_language response", response);
                if (response.status === 200) {
                    self.setState({language: this.state.changeLanguage, changeLanguageOpen: false})
                    self.handleClose();

                    eventBus.dispatch("updateSubmissionContainer", {rerun: false});
                    eventBus.dispatch("updateHeader", {rerun: false});
                    eventBus.dispatch("showLoader", {showLoader: false})
                } else {
                    alert("Invalid session code", localStorage.getItem("cSessionId"));
                    eventBus.dispatch("showLoader", {showLoader: false})
                }

            })
        }
    }



    render() {
        return (


                <div className={"languageSelectorBox"} >

                    {this.props.sessionType !== "shared" ?
                        <>
                            {content.chatScreenContent[this.state.language || "en-US"].myLanguageTabLabel}: {this.state.language}
                        </> :
                        <>{this.state.language}, {this.state.secondaryLanguage}</>
                    }



                    <IconButton onClick={(e) => {
                        this.setState({anchorEl: e.currentTarget, changeLanguageOpen: true})
                        //setAnchorEl(e.currentTarget);
                        //setChangeLanguageOpen(true);

                    }}>
                        <EditIcon style={{fontSize: "12px"}}/>
                    </IconButton>

                    <Popover id={"change-language-popover"}
                             open={this.state.changeLanguageOpen}
                             anchorEl={this.state.anchorEl}
                             anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: -210,
                             }}
                    >
                        <div style={{padding: "15px", border: "1px solid #ddd"}}>
                            <div style={{fontWeight: "bold"}}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].title}</div>
                            <div style={{display: "flex", flexDirection: "column"}}>

                            <div style={{padding: "10px"}}>
                                <div>
                                    {content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].description}
                                </div>


                                {this.state.conversationType ?
                                    <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                        <LanguageSelector language={this.state.changeLanguage} setLanguage={this.setLanguage}
                                                          label={content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].languageSelectLabel} mutliSelect={false}/>
                                    </div>
                                    : null
                                }

                            </div>

                            {this.props.sessionType === "shared" ?
                                <div style={{padding: "10px"}}>

                                    <div>
                                        {content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].secondaryDescription}
                                    </div>
                                    {this.state.conversationType ?
                                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                                            <LanguageSelector language={this.state.changeSecondaryLanguage} setLanguage={this.setSecondaryLanguage}
                                                              label={content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].secondaryLanguageSelectLabel} mutliSelect={false}/>
                                        </div>
                                        : null
                                    }
                                </div>

                                    : null
                            }
                            </div>

                            <div style={{textAlign: "right"}}>
                                <Button variant={"outlined"} onClick={() => {this.setState({changeLanguageOpen: false})}}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
                                <Button style={{marginLeft: "10px"}} variant={"outlined"} onClick={this.updateSession}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
                            </div>
                        </div>
                    </Popover>

                </div>




        );
    }
}


/*
<Dialog
    open={this.props.open}
    onClose={this.handleClose}
    PaperProps={{
        component: 'form',

    }}
>
    <DialogTitle>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].title}</DialogTitle>
    <DialogContent style={{padding: "10px"}}>
        <DialogContentText>
            {content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].description}
        </DialogContentText>


        {this.state.conversationType ?
            <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                  label={content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].languageSelectLabel} mutliSelect={false}/>
            </div>
            : null
        }

    </DialogContent>
    <DialogActions>
        <Button variant={"outlined"} onClick={this.handleClose}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].cancelButton}</Button>
        <Button variant={"outlined"} onClick={this.updateSession}>{content.updateChatLanguagePanel[localStorage.getItem("siteLanguage") || "en-US"].createButton}</Button>
    </DialogActions>
</Dialog>

 */

import React, {Component} from "react";
import {Button, IconButton, TextField} from "@mui/material";
import {
    getListeningState,
    getOtherInvokerState, setListeningState,
    setOtherInvokerState,
    setSuppressRestartState
} from "../../helpers/session";
import HearingIcon from "@mui/icons-material/Hearing";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import eventBus from "../../helpers/eventBus";

export default class ChatSubmissionContainer extends Component {

    state = {
        language: "",
        chatDetailObj: {},
        micContinuous: localStorage.getItem("micContinuous") === "true",
        listeningState: false,

    }


    componentDidMount() {
        console.log(this.props)
        this.setState({
            language: "",
            chatDetailObj: this.props.chatDetailObj || {}
        });
        eventBus.on("updateSubmissionContainer", (uObj) => {
            console.log("eventbus called")
            this.updateSessionData();
        });

        eventBus.on("updateListeningState", (uObj) => {
            //this.updateSessionData();
            //console.log("updateListeningState", uObj)
            this.setState({listeningState: uObj.isListening})
        });

        this.updateSessionData();
    }

    updateSessionData = () => {
        let self = this;
        console.log("getLanguagesFromSession")
        //let sessions = JSON.parse(localStorage.getItem("chatSessions")) || [];
        let session = localStorage.getItem("cSession") ? JSON.parse(localStorage.getItem("cSession")) : {};
        console.log("session!", session)
        this.setState({chatDetailObj: {...session},
            micContinuous: localStorage.getItem("micContinuous") === "true"
        })

        if (session.id === localStorage.getItem("cSessionId")) {
            //console.log(session)
            session.sessionMembers.forEach((member) => {
                if (member.userId === localStorage.getItem("uid")){
                    //console.log("member", member)
                    self.setState({language: member.language});
                    if (session.sessionType === "shared")
                    {
                        //console.log("shared")
                        session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                //console.log("language", lang)
                                self.setState({otherLanguage: lang});
                            }
                        })
                    }
                }
            })
        }


    }







    render() {
        return (
            <div className={"submissionsContainer"} style={{
                marginLeft: this.state.chatDetailObj && this.state.chatDetailObj.hasOwnProperty("sessionType") && this.state.chatDetailObj.sessionType === "shared" ? "0px" : 0,
                gap: this.state.chatDetailObj && this.state.chatDetailObj.hasOwnProperty("sessionType") && this.state.chatDetailObj.sessionType === "shared" ? "10px" : 0

            }}>

                <div className={"submissionSubcontainer"}  style={{width: "100%"}}>

                    <TextField value={this.props.entryText}
                               key="t2"
                               fullWidth
                        //disabled={isSpeaking}
                               onChange={(e) => {
                                   this.props.setQuery(e)
                               }}
                               multiline
                               inputProps={{
                                   maxLength: 300,
                               }}
                               style={{ borderColor: "#ddeeff", backgroundColor: "#fff",
                                   marginRight: "5px"
                               }}
                               onKeyDown={(e) => {
                                   this.props.onEnterPress(e)
                               }}>
                    </TextField>

                    <div style={{textAlign:"left", display: "flex", flexWrap: "none"}}>
                        <Button style={{

                            marginTop: "0px",
                            padding: 0,
                            color: "#083f45",
                            background: this.state.listeningState && !getOtherInvokerState() ? "#c5d5b0"  : "#fff"
                        }}
                                onClick={this.state.listeningState ?
                                    () => {
                                        console.log("click recordingState (turn off): ");
                                        setSuppressRestartState(true);
                                        setListeningState(false);
                                        this.setState({listeningState: false})
                                        console.log("setting recording state to false 5")
                                        this.props.updRecordingState(false)


                                    } : () => {
                                        console.log("click recordingState (turn on): ");
                                        console.log("setting recording state to true 6")

                                        setOtherInvokerState(false);
                                        this.props.turnOffMic();


                                        setListeningState(true);
                                        this.setState({listeningState: true})
                                        this.props.updRecordingState(true)
                                        if (localStorage.getItem("micContinuous") === "true"){
                                            setSuppressRestartState(false);
                                        } else {
                                            setSuppressRestartState(true);
                                        }

                                        this.props.setSpeakingLanguage(this.state.language);
                                        this.props.turnOnMic(this.state.language);

                                    }}>
                            {(this.props.activeSpeaking) && !getOtherInvokerState() ? <div><HearingIcon style={{margin: 0, padding: 0}} /><div style={{"fontSize": ".7rem"}}>{this.state.language}</div></div> : <div><MicIcon/><div style={{"fontSize": ".7rem"}}>{this.state.language}</div></div>}
                        </Button>
                        <IconButton disableRipple={true} disableFocusRipple={true} style={{

                            marginLeft: "5px",
                            right: "10px",
                            color: "#083f45"
                        }}
                                    onClick={(e) => {
                                        this.props.sendMessage(e)
                                    }}
                        ><SendIcon/></IconButton>
                    </div>
                </div>

                {this.state.chatDetailObj && this.state.chatDetailObj.hasOwnProperty("sessionType") && this.state.chatDetailObj.sessionType === "shared" ?
                    <div className={"submissionSubcontainer"} style={{width: "100%"}}>
                        <TextField value={this.props.otherEntryText}
                                   key="t1"
                                   fullWidth
                            //disabled={isSpeaking}
                                   onChange={(e) => {
                                       this.props.setOtherQuery(e)
                                   }}
                                   multiline
                                   inputProps={{ maxLength: 300 }}
                                   style={{ borderColor: "#ddeeff", backgroundColor: "#fff",
                                       marginRight: "5px"}}
                                   onKeyDown={(e) => {
                                       this.props.onOtherEnterPress(e)
                                   }}>
                        </TextField>

                        <div style={{textAlign:"left", display: "flex", flexWrap: "none"}}>
                            <Button style={{
                                marginTop: "0px",
                                padding: 0,
                                color: "#083f45",
                                background: this.state.listeningState && getOtherInvokerState() ? "#c5d5b0" : "#fff"
                            }}
                                    onClick={getListeningState() ?
                                        () => {

                                            console.log("click recordingState (turn off): ");

                                            setSuppressRestartState(true);
                                            console.log("setting recording state to false 5")
                                            this.props.updRecordingState(false)
                                            this.setState({listeningState: false})
                                            setOtherInvokerState(false);
                                            //stopSpeechToText();


                                        } : () => {
                                            console.log("setting listeningState to true")
                                            setListeningState(true);

                                            if (localStorage.getItem("micContinuous") === "true"){
                                                setSuppressRestartState(false);
                                            } else {
                                                setSuppressRestartState(true);
                                            }
                                            this.setState({listeningState: true})
                                            setOtherInvokerState(true);
                                            //startSpeechToText(otherLanguage);
                                            //setButtonRecordingState(true);
                                            this.props.updRecordingState(true);
                                            this.props.setSpeakingLanguage(this.state.otherLanguage);
                                            this.props.turnOffMic();
                                            this.props.turnOnMic(this.state.otherLanguage);

                                        }
                                    }>
                                {(this.props.activeSpeaking) && getOtherInvokerState() ? <div><HearingIcon style={{margin: 0, padding: 0}}/>
                                    <div style={{"fontSize": ".7rem"}}>{this.state.otherLanguage}</div>
                                </div> : <div><KeyboardVoiceIcon />
                                    <div style={{"fontSize": ".7rem"}}>{this.state.otherLanguage}</div>
                                </div>}
                            </Button>
                            <IconButton disableRipple={true} disableFocusRipple={true} style={{
                                marginLeft: "5px",
                                right: "10px",
                                color: "#083f45"
                            }}
                                        onClick={(e) => {
                                            this.props.sendOtherMessage(e)
                                        }}
                            ><SendIcon/></IconButton>
                        </div>
                    </div>
                    : null
                }





            </div>


        );
    }
}
